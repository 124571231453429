import React from "react";
import {
  FaAmazon,
  FaUtensils,
  FaGift,
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa";
import { RiNetflixFill } from "react-icons/ri";
import campaignImage from "../images/banner.jpg";
import campaignImage2 from "../images/banner-2.avif";

function CampaignScreen({ onEnterCampaign }) {
  return (
    <div className="flex flex-col h-full bg-white text-gray-800 md:flex md:items-center md:justify-center md:h-screen mt-2">
      {/* Content */}
      <div className="flex-grow overflow-y-auto p-4 pb-20 md:pb-4 md:flex md:items-center md:justify-center">
        {/* Campaign Card */}
        <div className="bg-white border border-gray-200 rounded-lg shadow-sm md:max-w-2xl w-full">
          {/* Campaign Image */}
          <div className="h-50 md:h-64">
            <img
              src={campaignImage2}
              alt="Campaign"
              className="w-full h-full"
            />
          </div>

          {/* Campaign Details */}
          <div className="p-4 md:p-8">
            <h2 className="text-2xl font-bold mb-2 md:text-3xl">
              Join Campaign, Earn Rewards
            </h2>
            <p className="text-gray-600 mb-4 md:text-lg">
              Complete tasks and enter a lucky draw!
            </p>

            <h3 className="font-semibold mb-2 md:text-xl">
              What you need to do:
            </h3>
            <ul className="space-y-3 mb-4">
              {[
                // {
                //   icon: (
                //     <RiNetflixFill className="text-red-600 text-xl md:text-2xl" />
                //   ),
                //   text: "Prove you have a Netflix account",
                // },
                {
                  icon: (
                    <FaAmazon className="text-orange-500 text-xl md:text-2xl" />
                  ),
                  text: "Prove your Amazon account!",
                },
                {
                  icon: (
                    <FaUtensils className="text-green-600 text-xl md:text-2xl" />
                  ),
                  text: "Prove your Swiggy account!",
                },
              ].map((item, index) => (
                <li
                  key={index}
                  className="flex items-center space-x-3 bg-gray-50 p-3 rounded-lg md:p-4"
                >
                  {item.icon}
                  <span className="text-sm md:text-base">{item.text}</span>
                </li>
              ))}
            </ul>

            <h3 className="font-semibold mb-2 md:text-xl">What you get:</h3>
            <p className="text-blue-600 font-bold text-lg md:text-2xl mb-6">
              Get a chance to win Coldplay Mumbai Tickets!
            </p>

            {/* Action Button - Inside the card for desktop */}
            <div className="hidden md:block">
              <button
                onClick={onEnterCampaign}
                className="w-full bg-blue-600 text-white font-bold py-3 rounded-lg transition duration-300 ease-in-out hover:bg-blue-700 md:text-xl md:py-4"
              >
                Enter the Campaign ⚡️
              </button>
            </div>

            {/* Grayed out Previous and Next */}
            {/* <div className="flex justify-between items-center mt-6 text-gray-300">
              <div className="flex items-center">
                <FaChevronLeft className="mr-1" />
                <span>Previous</span>
              </div>
              <div className="flex items-center">
                <span>Next</span>
                <FaChevronRight className="ml-1" />
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {/* Action Button - Fixed at bottom for mobile */}
      <div className="fixed bottom-0 left-0 right-0 p-4 bg-white border-t border-gray-200 md:hidden">
        <button
          onClick={onEnterCampaign}
          className="w-full bg-blue-600 text-white font-bold py-3 rounded-lg transition duration-300 ease-in-out hover:bg-blue-700"
        >
          Enter the Campaign ⚡️
        </button>
      </div>
    </div>
  );
}

export default CampaignScreen;
